<template>
  <v-container fluid class="pa-6">
    <div class="content">
      <h3>{{ $t('pageNotFound') }}</h3>
      <lottie-animation
        ref="anim"
        class="mt-4 animation"
        :animation-data="require('@/assets/animations/shrug.json')"
        :loop="true"
        :auto-play="true"
      />
      <v-btn rounded color="primary" @click="goToDashboard">{{ $t('backToDashboard') }}</v-btn>
    </div>
  </v-container>
</template>

<script>
import LottieAnimation from 'lottie-web-vue';
export default {
  name: 'NotFound',
  components: {
    LottieAnimation,
  },
  methods: {
    goToDashboard() {
      this.$router.push({ name: 'Dashboard' });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  font-size: 2em;
  text-align: center;
}
.animation {
  max-width: 400px;
  text-align: center;
  margin: auto;
}
</style>
